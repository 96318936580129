import Navbar from '@/components/Navbar';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import Head from 'next/head';

export default function Links() {
  return (
    <>
      <Head>
        <title>Kontakt</title>
        <meta
          name="description"
          content="Kontakt firmy Kancelaria Notarialna Maria Czarniak Patrycja Dorczyńska Sara Podkówka Spółka Cywilna"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar withoutHero />
      <div className="mt-[92px]" />
      <div
        className="flex flex-col items-center justify-center gap-4 py-24 px-4"
        style={createStyleForBackgroundImage('/hero-bg.png', {
          withDarkOverlay: true,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        })}
      >
        <div className="text-center text-white text-[35px] md:text-[45px] font-bold xl:w-[65%]">
          Błąd 404
        </div>
        <div className="bg-[#A5A5A5] h-[64px] w-[1px]" />
        <div className="text-[16px] text-white text-center xl:w-[60%] leading-10">
          Nie znaleziono strony
        </div>
      </div>
    </>
  );
}
